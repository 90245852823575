export const expensesCategories = [
    'Rent',
    'Ishtirak',
    'Diesel',
    'Inventory',
    'Cargo',
    'Food',
    'Water',
    'Internet',
    'Decor',
    'Salary',
    'Marketing: Offline',
    'Marketing: Influencers',
    'Marketing: Online ads',
    'Other'
];
