export const brands = [
    "babydola",
    "nipperland",
    "leoking",
    "andywawa",
    "tongs",
    "minibubbles",
    "minora",
    "bebus",
    "mixbabi",
    "toysi",
    "tafyy",
    "kts",
    "tiwinti",
    "msc",
    "belemir",
    "ladi",
    "miomini",
    "minipodyum",
    "eraykids",
    "babymy",
    "diab",
    "eleysa",
    "albadi",
    "beren",
    "yavrucak",
    "blackone",
    "yuko",
    "mondo",
    "roya",
    "lemon",
    "ecolkiz",
    "bebessi",
    "eray",
    "ness",
    "funny",
    "uzun",
    "mspn",
    "taskids",
    "corpi",
    "nirvana",
    "minicool",
    "h&m",
    "monnarosa",
    "adn",
    "mtn",
    "bln",
    "qumru",
    "laderra",
    "pilise",
    "moda",
    "newlenza",
    "meristore",
    "newmission",
    "levure",
    "benin",
    "bgn",
    "miss",
    "avrile",
    "biskuvi",
    "quqa",
    "turkmoda",
    "sorez",
    "pucka",
    "ramrod",
    "aynaz",
    "asist",
    "femkai",
    "nike",
    "adidas",
    "basicpark",
    "missron",
    "hatunatila",
    "toymall",
    "donino",
    "limones",
    "emins",
    "panda",
    "bignile",
    "fame",
    "kadriye",
    "nevermore",
    "moinoi",
    "hilalakinci",
    "dabron",
    "beybek",
    "nisantasi",
    "Zara",
    "Vauva",
    "Moes",
    "Defne Bebe",
    "Love",
    "Iggy",
    "West",
    "LaCashmere",
    "Baby Pattini",
    "Watani",
    "My City",
    "Nefise",
    "Gulseli",
    "Masalim",
    "Sola Rosa",
    "Yellowmoon",
    "Fashion",
    "Vaniza",
    "Wink Wink",
    "Converse",
    "New Balance",
    "Chanel",
    "Tommy Hilfiger",
    "Nike",
    "Puma",
    "Sateen",
    "Ikoll",
    "Bypommes",
    "Troya",
    "Moda Yassin",
    "Fendel",
    "Seemore",
    "LBBFashion",
    "Elti",
    "Acarlife",
    "Yassmenta",
    "Alya",
    "Babyjem",
    "Caramell",
    "Pomp Shoes",
    "Osso",
    "Puane",
    "Cigit",
    "Antique",
    "Magazine",
    "Soul",
    "Ashilda",
    "Miss Churchil",
    "Koala",
    "RG kids",
    "Azizbebe",
    "Bibaby",
    "Biorganic",
    "Seviebébé",
    "Cumino",
    "Bébé konfor",
    "M&Y"
];
