export const generalSizes = [
    "One-Size",
    "XXS",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL"
];
