export const productCategories = [
    "T-shirt",
    "Shirt",
    "Pants",
    "Short",
    "Skirt",
    "Dress",
    "Coat",
    "Hoodie",
    "Accessories",
    "Shoes",
    "Socks",
    "Underwear",
    "Pyjama",
    "Set:Baby",
    "Set:Hospital",
    "Set:Kid",
    "Set:Woman",
    "Tank Top",
    "Body",
    "Long-Sleeve",
    "New-born",
    "Overall",
    "Jacket",
    "Vest",
    "Sweater"
];
