export const babySizes = [
    "0 - 1m",
    "1m",
    "0 - 3m",
    "1 - 2m",
    "2 - 3m",
    "1 - 3m",
    "3m",
    "3 - 4m",
    "4 - 5m",
    "5 - 6m",
    "3 - 6m",
    "6m",
    "6 - 7m",
    "6 - 9m",
    "9m",
    "9 - 12m",
    "12m",
    "1y",
    "12 - 18m",
    "18m",
    "18 - 24m",
    "24m",
    "2y",
    "1 - 2y",
    "24 - 36m",
    "36m",
    "3y",
    "2 - 3y"
];
