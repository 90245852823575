export const kidsSizes = [
    "4y",
    "3 - 4y",
    "5y",
    "4 - 5y",
    "6y",
    "5 - 6y",
    "7y",
    "6 - 7y",
    "8y",
    "7 - 8y",
    "9y",
    "8 - 9y",
    "10y",
    "9 - 10y",
    "11y",
    "10 - 11y",
    "12y",
    "11- 12y",
    "13y",
    "12 - 13y",
    "14y",
    "13 - 14y",
    "15y",
    "14 - 15y",
    "16y",
    "15-16y"
];
