export const backgroundColors = [
    "#DB143C",
    "#FAC3CE",
    "#FF8C00",
    "#FFE8CC",
    "#483D8B",
    "#BBB5DF",
    "#696969",
    "#CDCDCD",
    "#B12222",
    "#EFAFAF",
    "#FDA400",
    "#FFEDCC",
    "#4A0080",
    "#CE78FE"
];